import React, { Suspense } from 'react'
import PowerGrid from './powerGrid/PowerGrid'

import './ClientsTable.scss'
import { PowerGridAction } from './powerGrid/PowerGridConfig'
import { withTranslation } from 'react-i18next'
import GeneratedInvoice from '../../pages/invoices/new-invoice/generated-invoice/PrintInvoice'
import useInvoices from '../../hooks/useInvoices'
import LanguageContext from '../core/i18n/LanguageContext'
import invoiceService from '../../services/invoiceService'
import { useToast } from './toast/ToastProvider'
import { ToastType } from './toast/ToastItem'
import { useNavigate } from 'react-router-dom'
import InvoiceStateRenderer from '../app/fragments/InvoiceStateRenderer'

const InvoicesTable: React.VFC = () => {
    const [selectedInvoiceId, setSelectedInvoiceId] = React.useState(null)
    const [invoices, mutate] = useInvoices()
    const toast = useToast()
    let navigate = useNavigate()

    const deleteInvoice = async (invoiceId: number | string) => {
        invoiceService
            .delete(invoiceId)
            .then((e) =>
                toast.showMessage({
                    title: 'Kill bills',
                    description: `Invoice ${invoiceId} deleted`,
                    type: ToastType.SUCCESS,
                })
            )
            .catch((r: Error) => toast.showError(r.message))
            .finally(mutate)
    }

    const setEditableInvoiceId = async (invoiceId: number | string) => {
        navigate(`/Invoices/${invoiceId}`)
    }

    const config = {
        dataSource: invoices,
        key: (key: any) => key.id,
        columns: [
            {
                field: 'commonData',
                key: 'invoiceId',
                localizedTitle: 'strings.invoice_number',
                formatter: (e: any) => {
                    const { invoiceNumber } = e
                    return `${invoiceNumber}`
                },
            },
            {
                field: 'client',
                title: 'Client',
                localizedTitle: 'noun.client',
                formatter: (e: any) => {
                    const { title } = e
                    return `${title}`
                },
            },
            {
                field: 'commonData',
                key: 'date',
                title: 'Date',
                localizedTitle: 'noun.date',
                formatter: (data: any) => {
                    const { createDate } = data
                    return `${createDate}`
                },
            },
            {
                field: 'commonData',
                key: 'state',
                localizedTitle: 'strings.invoice_status',
                textAlign: 'center',
                formatter: (e: any) => {
                    return <InvoiceStateRenderer state={e.state} />
                },
            },
            {
                field: 'content',
                title: 'Amount',
                localizedTitle: 'noun.amount',
                formatter: (data: any) => {
                    const { total } = data
                    return total
                },
            },
        ],
        actions: [
            {
                actionType: PowerGridAction.Download,
                onClick: (invoice: any) => {
                    setSelectedInvoiceId(invoice.invoiceId)
                },
            },
            {
                actionType: PowerGridAction.Edit,
                onClick: (invoice: any) => {
                    setEditableInvoiceId(invoice.invoiceId)
                },
            },
            {
                actionType: PowerGridAction.Delete,
                onClick: (invoice: any) => {
                    deleteInvoice(invoice.invoiceId)
                },
            },
        ],
    }

    return (
        <>
            <PowerGrid config={config}></PowerGrid>
            <LanguageContext>
                {selectedInvoiceId && (
                    <GeneratedInvoice
                        invoiceId={selectedInvoiceId}
                        isOpen={!!selectedInvoiceId}
                        onClose={() => {
                            setSelectedInvoiceId(null)
                        }}
                    />
                )}
            </LanguageContext>
        </>
    )
}

export default withTranslation()(InvoicesTable)
