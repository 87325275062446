{
  "todayButton": "Today",
  "nextMonth": "Next month",
  "previousMonth": "Previous month",
  "nextYear": "Next year",
  "previousYear": "Previous year",
  "weekdays": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  "months": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}
