import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { secondInstance } from './setupTranslations'

const LanguageContext: React.FC = (props) => {
    const { children } = props

    return <I18nextProvider i18n={secondInstance}>{children}</I18nextProvider>
}

export default LanguageContext
